exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-password-js": () => import("./../../../src/pages/account/password.js" /* webpackChunkName: "component---src-pages-account-password-js" */),
  "component---src-pages-account-success-js": () => import("./../../../src/pages/accountSuccess.js" /* webpackChunkName: "component---src-pages-account-success-js" */),
  "component---src-pages-admin-registrations-js": () => import("./../../../src/pages/admin/registrations.js" /* webpackChunkName: "component---src-pages-admin-registrations-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-how-to-use-js": () => import("./../../../src/pages/how-to-use.js" /* webpackChunkName: "component---src-pages-how-to-use-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-confirm-js": () => import("./../../../src/pages/orderConfirm.js" /* webpackChunkName: "component---src-pages-order-confirm-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-shop-v-2-js": () => import("./../../../src/pages/shopV2.js" /* webpackChunkName: "component---src-pages-shop-v-2-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-styling-js": () => import("./../../../src/pages/styling.js" /* webpackChunkName: "component---src-pages-styling-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-team-details-js": () => import("./../../../src/pages/team/details.js" /* webpackChunkName: "component---src-pages-team-details-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-players-js": () => import("./../../../src/pages/team/players.js" /* webpackChunkName: "component---src-pages-team-players-js" */),
  "component---src-pages-tournament-sample-js": () => import("./../../../src/pages/tournament/sample.js" /* webpackChunkName: "component---src-pages-tournament-sample-js" */)
}

